.searchIcon{width: 30px; height: 30px; border-radius: 50%;}
.navLink{height: 100%;}
.navLink>li>a{color: #fff;}
.studentsPro button:nth-child(1){margin-right: 3%;}
.active{border-bottom: #ADA1FF solid 4px;}
.Banner{height:100vh;}
.loading img{height: 2rem;}
.logo img{height: 80px;}
.BannerHyper{bottom:-90px; height: 182px;}
.BannerHyper>div:nth-child(1){border-radius: 2rem 0 0 2rem;}
.BannerHyper>div:nth-child(2){border-radius: 0 2rem 2rem 0;}
.Course{background-color:#E7E6E6;}
.CourseDetails{display: grid; grid-template-columns: repeat(3,1fr); grid-template-rows: repeat(auto,1fr); grid-gap: 1.5rem;}
.CourseDetails>li{height: 450px; overflow: hidden; cursor: pointer; background-color: var(--c7); border-radius: 1.5rem; transition: all 500ms ease-in-out;}
.CourseDetails >li:hover{transform: scale(1.05);}
.courseDList label{background-color: var(--c5); margin-bottom:.2rem; padding: .2rem .5rem;}
.knowMoreBtn{visibility:hidden; opacity: 0; transition: all 1s ease;}
.CourseDetails >li:hover .knowMoreBtn{visibility:visible; opacity: 1; }
.CourseDetails >li:hover img{height: 20%;}
.CourseDetails>li:nth-child(odd){background:#020726;}
.CourseDetails>li:nth-child(even){background: #080f43;}
.CourseDetails>li>img{border-radius:1.5rem; height: 60%; object-fit: cover; width: 100%; transition: all 400ms ease-in-out;}
.star {color:#FFCE31;}
.TitleHead{position: relative; display: flex; justify-content: center;}
.TitleHead::before{content: ''; width: 100px; height: 3px; background-color:#FD8BFF; position: absolute; bottom: -15px;}
.welcome{background-color:#5E4ED1;}
.stundGame{background-image: url(../public/portfolio.jpg); background-size: cover; height: 70vh;}
.Recruite{background-color: #1D2A73;}
.Recruite ul>li{margin:2%;}
.whyChoose{background-color: #F2F2F2;}
.whyChoose ul>li div{margin: 1rem 0;}
.whyChoose ul>li div>h5{font-family: var(--f3); font-weight: 500; }
.testimo{overflow:hidden;}
.testimoCanvas{display: flex;}
.testimoCanvas>li{margin:0 1rem;}
.subFooter{background-color:#0E163F ;}
.footer{color: var(--c7);}
.footerNav div li{margin: .5rem 0; border-bottom: 1px dotted #333; padding-bottom: 2%;}
.footerNav  div li a{padding-bottom: .5rem;}
.quickForm{background-color: rgba(0,0,0,.7); width: 100%; height: 100vh; position:fixed; top: 0; bottom: 0; z-index: 9;}
.quickForm form{background-color: #6A5BDC;}
.quickForm form input, .quickForm form select{margin-bottom: 1rem; border: none; background-color: #fff; }
.quickForm form button{margin-top: 1rem;}

.quickForm1{width: 100%; height: 100%; }
.quickForm1 form{background-color: #ffff;}
.quickForm1 form input, .quickForm1 form select, .quickForm1 form textarea{margin-bottom: 1rem; border: none; background-color: rgb(207, 207, 207); }
.quickForm1 form textarea{height: 150px;}
.quickForm1 form button{margin-top: 1rem;}

.formCloseBtn{width: 40px; cursor: pointer; font-size: 1.2rem; color:var(--c7); height: 50px; border-radius: 0 .5rem .5rem 0; background-color:var(--c5); position: absolute; right: -40px; top: 5%;}
.formCloseBtn:hover{background-color: var(--c4); color: var(--c7);}
.breadcrumb{height: 150px; background-image: url(../public/breadcums.jpg); background-position: center center; }
.breadcrumb h3{text-shadow: 7px 1px 5px rgba(0,0,0,0.77);}
.map img{width: 100%; height: 100%; object-fit: cover;}
.socialIcon a{font-size: 1.5rem; margin-right: .5rem;}

.Megagallery{  display: grid; grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); grid-auto-rows: 120px;  grid-auto-flow: dense; }
.Megagallery>li{width: 100%;}
.Megagallery img{width: 100%; height: 100%;}
.horizontal {grid-column: span 2;}
.vertical {grid-row: span 2;}
.big {grid-column: span 2;grid-row: span 2;}

.sticky {position: sticky; top: 0; z-index: 0;}
.whyWe img{width: 100%; height:auto;}
.bigClod{bottom:-4rem; width: 650px!important; z-index:7;}
.handburge,.handburgeClose{display: none;}
#menuCheck{display: none;}
.subFooter button:nth-child(1){margin-right: 3%;}

.errorMsg{margin-bottom: .2rem; border-radius: .5rem;}

/* Big Screen view port */
@media screen and (min-width: 1401px) {  
    .wrapper{width:90%; height: auto;}  
    .Banner{height: auto; padding-bottom: 90px;}
    .Banner h3{font-size: 4rem; line-height: 4.3rem;}
    .Banner p{font-size: 2rem; line-height: 2.3rem; width: 90%;}   
}


/* PC device view port */
@media screen and (max-width: 1400px) {   
    .Banner{height: auto; padding-bottom: 90px;}   
    .stundGame{height: auto; padding: 5rem 0;}
    .bannerImg img{width: 100%;}
}

/* Laptop view port */
@media screen and (max-width: 900px) {
    .bannerImg img, .bigClod{width: 100%;}
    .welcome{height: auto; padding: 7% 0;}
    .CourseDetails>li>img{height: auto;}
    .knowMoreBtn{visibility:visible; opacity: 1; }
    .CourseDetails>li{height: auto;}
    .CourseDetails >li:hover img{height: 100%;}
    .subFooter button:nth-child(1){margin-right: 3%;}
    .Banner{height: auto;}
    .aboutImg img{width:50%;}
    .footerNav {flex-direction: column;}
    .BannerHyper{display: none;}
    .CourseDetails{grid-template-columns: repeat(1,1fr); padding: 0; height: auto;}
    .CourseDetails>li{display: flex;}
    .CourseDetails>li>img{width: 40%;}
    .whyChoose  ul{flex-direction: column; flex-wrap: wrap;}
    .whyChoose  ul>li{width: 100%; }
    .whyChoose  ul>li>div{background-color: #e2e2e2; padding:1rem;}
    .whyChoose  ul>li:nth-child(2){display: none;}
    .navLink{width: 100%; height: 100vh; background-color: rgba(23, 4, 36, 0.9); position: fixed; z-index: 9; top: 0; right: 0; display: none; flex-direction: column; justify-content:flex-start; padding:2rem;}
    .navLink>li{border-bottom: 2px dotted rgb(150, 25, 161); width: 100%;}
    .navLink>li>a{padding: 5% 0; font-size: 22px; color: var(--c2);}
    .map{margin:1.5rem; margin-top: 2rem;}
    .handburge,.handburgeClose{display: block;}
    .topContact{padding-right: 5%;}
    .handburge{position: absolute; right:1rem; font-size: 2rem;}  
    #menuCheck:checked ~ .navLink{display: block;}   
    .active{border-bottom: none;} 
    .aboutContent{flex-direction: column;}  
}

@media screen and (max-width: 598px) {
    .App{overflow: hidden;}
    .breadcrumb{display: none;}
    .BannerHyper{display: none;}
    .Banner{height: auto;}
    .bannerImg img, .bigClod{width: 100%;}
    .CourseDetails{grid-template-columns: repeat(1,1fr); padding: 0;}
    .CourseDetails>li{height: auto; flex-direction: column; padding-bottom:10%;  overflow: hidden; cursor: pointer; background-color: var(--c7); border-radius: 1.5rem; transition: all 500ms ease-in-out;}    
    .knowMoreBtn{visibility:visible; opacity: 1; }
    .CourseDetails>li>img{height: auto; width:100%;}
    .handburgeClose, .handburge{font-size: 2.5rem;}
    .welcome h5{text-align:justify ;}
    .whyWe, .topContact{display: none;}
    .subFooter{padding: 5% 0;}
    .aboutImg img{width:100%;}
    .subFooter button:nth-child(1){margin-bottom: 3%;}
    .navLink{width: 100%; height: 100vh; background-color: rgba(23, 4, 36, 0.9); position: fixed; z-index: 9; top: 0; right: 0; display: none; flex-direction: column; justify-content:flex-start; padding:2rem;}
    .navLink>li{border-bottom: 2px dotted rgb(150, 25, 161); width: 100%;}
    .navLink>li>a{padding: 5% 0; font-size: 22px; color: var(--c2);}
    .map{margin: 0; margin-top: 2rem;}
    .handburge,.handburgeClose{display: block;}
    #menuCheck:checked ~ .navLink{display: block;}
    .formCloseBtn{width: 50px; cursor: pointer; font-size: 1.2rem; color:var(--c7); height: 50px; border-radius:50% ; background-color:var(--c5); position: absolute; right:2%; top:2%;}    
    .CourseDetails >li:hover{transform: scale(1);}
    .CourseDetails >li:hover img{height:fit-content;}
    .studentsPro button:nth-child(1){margin-right: 0%;}
    .testimoCanvas{flex-direction: column;}
    .testimoCanvas li{margin-bottom: 3%; border-radius: 0;}
    .whyChoose ul>li>div{flex-direction: column;}

}