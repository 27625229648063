 /* MY BOOSTSTRAP - V7*/
/* this style was generated by UI/UX developer Mr.Sagar Surendhrababu - www.sagarts.com */

/* //////////////////////////Basics foundation////////////////////////////////////////// */
*{padding: 0; margin: 0; box-sizing: border-box; list-style: none; text-decoration: none;}

/* //////////////////////////project fonts control zone////////////////////////////////////////// */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root{
    --c1:#6A5BDC;
    --c2:#E970EC;
    --c3:#1D2A73;
    --c4:#E13939;
    --c5:#30388C;
    --c6:#2BB080;
    --c7:#f2f2f2;
    --c8:#ADA1FF;
    --c9:#000000;
    --c10:#cac9c9;    

    --f1:"Roboto";
    --f2:"Poppins";
    --f3:"Ubuntu";
}

html{scroll-behavior: smooth; overflow-x: hidden; font-size: 16px;}
.mh{min-height: 100vh;}
/* //////////////////////////////////////////////////////////////form style//////////////////////////////////// */
input,select,textarea{height: 3.2rem; outline: none !important; font-size: 1rem; width: 100%; font-family: var(--f3); color: var(--c5); padding: 0 1rem; margin: 0 .2rem; border-radius: .5rem;}
input:focus{box-shadow: 0 0 10px var(--c5); background-color: #9DFF95!important;}  
/* //////////////////////////////////////////////////////////////Color & Background//////////////////////////////////// */
.c1t{color:var(--c1);}
.c2t{color:var(--c2);}
.c3t{color:var(--c3);}
.c4t{color:var(--c4);}
.c5t{color:var(--c5);}
.c6t{color:var(--c6);}
.c7t{color:var(--c7);}
.c8t{color:var(--c8);}
.c9t{color:var(--c9);}
.c10t{color: var(--c10);}

.c1b{background-color:var(--c1);}
.c2b{background-color:var(--c2);}
.c3b{background-color:var(--c3);}
.c4b{background-color:var(--c4);}
.c5b{background-color:var(--c5);}
.c6b{background-color:var(--c6);}
.c7b{background-color:var(--c7);}
.c8b{background-color:var(--c8);}
.c9b{background-color:var(--c9);}

/* //////////////////////////////////////////////////////////////Font Weight & Style//////////////////////////////////// */
.lf{font-weight: 200;}
.mf{font-weight: 400;}
.bf{font-weight: 600;}

.i{font-style: italic!important;}

.f1{font-family: var(--f1)!important;}
.f2{font-family: var(--f2)!important;}
.f3{font-family: var(--f3)!important;}

/* //////////////////////////////////////////////////////////////Border Radius//////////////////////////////////// */
.br{border-radius: 1rem;}
.br2{border-radius: 2rem;}

/* //////////////////////////////////////////////////////////////Colum style//////////////////////////////////// */
.col1{width: 8.3%; float: left;}
.col2{width: 16.6%; float: left;}
.col3{width: 25%; float: left;}
.col4{width: 33.3%; float: left;}
.col5{width: 41.6%; float: left;}
.col6{width: 50%; float: left;}
.col7{width: 58.3%; float: left;}
.col8{width: 66.6%; float: left;}
.col9{width: 75%; float: left;}
.col10{width: 83.3%; float: left;}
.col11{width: 91.6%; float: left;}
.col12{width: 100%; float: left;} 

/* ///////////////////////////////////////////Headline/////////////////////////////////////// */
.dTxt{font-size: 3.6rem!important}
h1 {font-size: 3rem; line-height: 3.4rem;}
h2 {font-size: 2.8rem; line-height: 3.5rem;}
h3 {font-size: 2.6rem; line-height: 3rem;}
h4 {font-size: 1.8rem; line-height: 2.2rem;}
h5 {font-size: 1.3rem; line-height: 1.7rem;}
h6 {font-size: 1rem; line-height: 1.4rem;}
p {font-size: 1rem; font-weight:normal; line-height: 1.6rem;}
a {font-size: 1rem; font-weight:normal; display: block;}
.sT{font-size: 0.9rem; line-height: 1.2rem;}
.xT{font-size: 0.8rem; line-height: 1.1rem;}

/* ///////////////////////////////////////////Padding Setups for Common/////////////////////////////////////// */

.pa{padding: 1rem;}
.pa2{padding: 2rem;}
.pa4{padding: 4rem;}
.pa8{padding: 8rem;}

.pt{padding-top: 1rem;}
.pt2{padding-top: 2rem;}
.pt4{padding-top: 4rem;}
.pt8{padding-top: 8rem;}

.pr{padding-right: 1rem;}
.pr2{padding-right: 2rem;}
.pr3{padding-right: 4rem;}
.pr4{padding-right: 8rem;}

.pb{padding-bottom: 1rem;}
.pb2{padding-bottom: 2rem;}
.pb4{padding-bottom: 4rem;}
.pb8{padding-bottom: 8rem;}

.pl{padding-left: 1rem;}
.pl2{padding-left:2rem;}
.pl4{padding-left:4rem;}
.pl8{padding-left:8rem;}

.prl{padding:0 1rem;}
.prl2{padding:0 2rem;}
.prl4{padding:0 4rem;}
.prl8{padding:0 8rem;}

.ptb{padding:1rem 0;}
.ptb2{padding:2rem 0;}
.ptb4{padding:4rem 0;}
.ptb8{padding:8rem 0;}

/* ///////////////////////////////////////////Margin Setups for Common/////////////////////////////////////// */
.ma{margin: 1rem;}
.ma2{margin: 2rem;}
.ma4{margin: 4rem;}
.ma8{margin: 8rem;}

.mt{margin-top: 1rem;}
.mt2{margin-top: 2rem;}
.mt4{margin-top: 4rem;}
.mt8{margin-top: 8rem;}

.mr{margin-right: 1rem;}
.mr2{margin-right: 2rem;}
.mr3{margin-right: 4rem;}
.mr4{margin-right: 8rem;}

.mb{margin-bottom: 1rem;}
.mb2{margin-bottom: 2rem;}
.mb4{margin-bottom: 4rem;}
.mb8{margin-bottom: 8rem;}

.ml{margin-left: 1rem;}
.ml2{margin-left:2rem;}
.ml4{margin-left:4rem;}
.ml8{margin-left:8rem;}

.mrl{margin:0 1rem;}
.mrl2{margin:0 2rem;}
.mr4{margin:0 4rem;}
.mr8{margin:0 8rem;}

.mtb{margin:1rem 0;}
.mtb2{margin:2rem 0;}
.mtb4{margin:4rem 0;}
.mtb8{margin:8rem 0;}


/* ///////////////////////////////////////////Aligment/////////////////////////////////////// */
.tar{text-align: right;}
.tal{text-align: left;}
.taj{text-align:justify;}
.tac{text-align: center;}

.fl{float: left;}
.fr{float: right;}

/* ///////////////////////////////////////////Absolute/Relative/////////////////////////////////////// */

.rel{position: relative;}
.abs{position: absolute;}

/* ///////////////////////////////////////////Flex/////////////////////////////////////// */

.flex{display: flex;}
.flexW{display: flex; flex-wrap: wrap;}
.flexC{display: flex; justify-content: center;}
.flexL{display: flex; justify-content: left;}
.flexR{display: flex; justify-content: right;}

.flexCC{display: flex; justify-content: center; align-items: center;}
.flexLC{display: flex; justify-content: left; align-items: center;}
.flexRC{display: flex; justify-content: right; align-items: center;}
.flexSAC{display: flex; justify-content:space-around; align-items: center;}
.flexSBC{display: flex; justify-content:space-between; align-items: center;}

.flexDC{display: flex; flex-direction: column;}
.flexDCC{display: flex; flex-direction: column; align-items: center; justify-content: center;}
.flexDCL{display: flex; flex-direction: column; align-items:flex-start; justify-content:center;}
.flexDCR{display: flex; flex-direction: column; align-items:flex-end; justify-content: center;}
.flexDSAC{display: flex; flex-direction: column; align-items:center; justify-content: space-around;}
.flexDSBC{display: flex; flex-direction: column; align-items:center; justify-content: space-between;}


/* ///////////////////////////////////////////Drop Shadow/////////////////////////////////////// */
.ds1{box-shadow: 0 3px 6px rgba(0,0,0,.5);}
.ds2{box-shadow: 0 3px 6px rgba(0,0,0,.2);}

/* ///////////////////////////////////////////Radius Corner/////////////////////////////////////// */
.br0{border-radius: .5rem;}
.br{border-radius: 1rem;}
.br2{border-radius: 1.5rem;}
.br3{border-radius: 2rem;}
.br4{border-radius: 2.5rem;}
.bn{border: none;}

/* //////////////////////////////////////////////////////////////Button style//////////////////////////////////// */
.btn0{padding: .3rem .8rem; display: inline-flex; cursor: pointer; font-size: .9rem!important; justify-content: center; align-items: center; font-family:var(--pFont);}
.btn1{padding: 1.5rem 2rem; cursor: pointer; display: inline-flex; font-size: 1.2rem!important; justify-content: center; align-items: center; font-family:var(--pFont); }
.btn2{padding: 1rem 2rem; cursor: pointer; display: inline-flex; font-size:1.1rem!important; justify-content: center; align-items: center; font-family:var(--pFont); }
.btn3{padding: .5rem 2rem; cursor: pointer; display: inline-flex; font-size: 1rem!important; justify-content: center; align-items: center; font-family:var(--pFont); }


.btn0 span,.btn1 span, .btn2 span, .btn3 span {display: inline-block;position: relative;transition: 0.5s;}
.btn0 span:after,.btn1 span:after, .btn2 span:after, .btn3 span:after { content: '\00bb'; font-size: 1.5rem; position: absolute;opacity: 0;top: -0.5rem;right: -20px;transition: 0.5s;}
.btn0:hover span ,.btn1:hover span , .btn2:hover span , .btn3:hover span {padding-right: 25px;}
.btn0:hover span:after, .btn1:hover span:after, .btn2:hover span:after, .btn3:hover span:after {opacity: 1;right: 0;}


.fieldError{background-color: #FF84BF!important; color: #fff!important; animation: shakeHead 900ms ease-in-out;}
@keyframes shakeHead{0% {transform: translateX(10px);}10% {transform: translateX(-10px);}20% {transform: translateX(10px);}30% {transform: translateX(-10px);}40% {transform: translateX(10px);}
50% {transform: translateX(-10px);}60% {transform: translateX(10px);}70% {transform: translateX(-10px);}80% {transform: translateX(10px);}90% {transform: translateX(-10px);}
}

/* ////////////////////////////////////////////Responsive Magic //////////////////////////////////////////////// */

/* Big Screen view port */
@media screen and (min-width: 1401px) {  
    .wrapper{width:90%; height: auto;}    
}

/* PC device view port */
@media screen and (max-width: 1400px) {   
    .wrapper{width:90%; height: auto;}            
}

/* Laptop view port */
@media screen and (max-width: 900px) {
    .wrapper{width:90%; height: auto;}
    /* colum settings */
    .md1{width: 12.5%; float: left;}
    .md2{width: 25%; float: left;}
    .md3{width: 37.5%; float: left;}
    .md4{width: 50%; float: left;}
    .md5{width: 62.5%; float: left;}
    .md6{width: 75%; float: left;}
    .md7{width: 87.5%; float: left;}
    .md8{width: 100%; float: left;}     

}  
/* Mobile view port */      
@media screen and (max-width: 598px) {
    .wrapper{width:90%; height: auto;}
    /* colum settings */ 
    .sm1{width: 25%; float: left;}
    .sm2{width: 50%; float: left;}
    .sm3{width: 75%; float: left;}
    .sm4{width: 100%; float: left; }
    /* auto Height */
    .autoH{height: auto;}

    /* ///////////////////////////////////////////Flex/////////////////////////////////////// */

    .sflex{display: flex;}
    .sflexW{display: flex; flex-wrap: wrap;}    
    .sflexC{display: flex; justify-content: center;}
    .sflexL{display: flex; justify-content: left;}
    .sflexR{display: flex; justify-content: right;}

    .sflexCC{display: flex; justify-content: center; align-items: center;}
    .sflexLC{display: flex; justify-content: left; align-items: center;}
    .sflexRC{display: flex; justify-content: right; align-items: center;}
    .sflexSAC{display: flex; justify-content:space-around; align-items: center;}
    .sflexSBC{display: flex; justify-content:space-between; align-items: center;}

    .sflexDC{display: flex; flex-direction: column;}
    .sflexDCC{display: flex; flex-direction: column; align-items: center; justify-content: center;}
    .sflexDCL{display: flex; flex-direction: column; align-items:flex-start; justify-content:center;}
    .sflexDCR{display: flex; flex-direction: column; align-items:flex-end; justify-content: center;}
} 